import './App.css';
import React from 'react';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Upload from './components/Upload';
import Grids from './components/Grids';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import GeneratePage from './components/GeneratePage';
import {AuthProvider} from "./provider/AuthProvider";
import LoginCallBack from "./components/LoginCallBack";
import ProtectedRoute from "./components/ProtectedRoute";
import NotFoundOrRedirect from "./components/NotFoundOrRedirect";


export default function App() {
    return (
        <>
            <Router>
                <AuthProvider>
                    <Navbar title="Function Agenda Builder"/>

                    <Routes>
                        <Route path="/" element={<Login/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route
                            path="/grid"
                            element={
                                <ProtectedRoute>
                                    <Grids/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/upload"
                            element={
                                <ProtectedRoute>
                                    <Upload/>
                                </ProtectedRoute>

                            }/>
                        <Route
                            path="/generatePage"
                            element={
                                <ProtectedRoute>
                                    <GeneratePage/>
                                </ProtectedRoute>
                            }
                        />
                        <Route path="/loginCallBack" element={<LoginCallBack/>}/>
                        <Route path="*" element={<NotFoundOrRedirect/>}/>
                    </Routes>
                    {/*<Footerbar />*/}
                </AuthProvider>
            </Router>
        </>
    );
}