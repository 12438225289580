import React, { useEffect, useState, useContext } from 'react';
import AuthContext from "../context/AuthContext";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useInterval } from '../hooks/UseInterval';
import { changeFileExtension, addUniqueIdToEvents, assignEventDayNumbers, getUpdatedEvents } from '../utils/common-utils';
import { fetchData } from "../api/axiosConfig";
import * as _ from "lodash";

const GeneratePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userRecords, clearUserSession } = useContext(AuthContext);

  const [generateState, setGenerateState] = useState({
    isRunning: true,
    count: 0,
    generateProgress: 5,
    errorMessage: "",
    apiToken: userRecords?.accessToken || "",
    isGenerateProcessDone: false,
    finalJsonOutput: null,
    defaultCreateAPIAction: true,
    invalidToken: false,
    tryAgain: false
  });

  const [loadingState, setLoadingState] = useState(5);

  const inputFileName = location?.state?.fileName;
  const start_date = location?.state?.start_date;
  const end_date = location?.state?.end_date;
  const outputFileName = changeFileExtension({ name: location?.state?.fileName });

  const getJsonOutputFile = async () => {
    const retrieveCallParams = {
      fileName: outputFileName
    }

    const retrieveCallHeaders = {
      'Content-Type': "application/json",
      'Authorization': generateState.apiToken
    }

    return fetchData('/retrieve', retrieveCallParams, retrieveCallHeaders);
  }

  useInterval(() => {

    getJsonOutputFile().then((response) => {
      if (response?.data?.status?.toLowerCase() === "failed") {
        setGenerateState((prevState) => {
          return {
            ...prevState,
            isRunning: false,
            errorMessage: response?.data?.message || 'Unable to create the event agenda'
          }
        });
      } else if (response?.data?.status === 'inProgress' && generateState.count === 60) {
        setGenerateState((prevState) => {
          return {
            ...prevState,
            isRunning: false,
            errorMessage: "The API has timed out"
          }
        });
      } else if (response?.data?.status === 'inProgress' && generateState.count < 60) {
        console.log("inProgress")
        // setting this 98 condition so that a file that is about to API timeout doesnt fill the whole loading bar
        if (loadingState < 98){
          setLoadingState(loadingState + 1.5)
        }
        setGenerateState((prevState) => {
          return {
            ...prevState,
            isRunning: true,
            count: (prevState.count + 1),
            generateProgress: (prevState.generateProgress + 1.5)
          }
        })
      } else {
        setLoadingState(100)
        setGenerateState((prevState) => {
          return {
            ...prevState,
            isRunning: false,
            generateProgress: 100,
            finalJsonOutput: response.data,
            isGenerateProcessDone: true
          }
        });
      }
    }).catch((error) => {
      console.log(`error in generate retrieve api call  (interval call)`, error);

      if (error?.response?.status === 401) {
        clearUserSession();
        setGenerateState((prevState) => {
          return {
            ...prevState,
            isRunning: false,
            invalidToken: true
          }
        });
      } else {
        setGenerateState((prevState) => {
          return {
            ...prevState,
            isRunning: false,
            errorMessage: error?.message || 'Something went wrong. Retrieve Call failed. Please check logs'
          }
        });
      }
    })
  }, generateState.isRunning ? 30000 : null);


  useEffect(() => {
    const generateOutputCall = async () => {
      const generateOutputCallParams = {
        filename: inputFileName,
        start_date,
        end_date
      }
      const generateOutputCallHeaders = {
        'Content-Type': "application/json",
        'Authorization': generateState.apiToken
      }

      return fetchData('/create', generateOutputCallParams, generateOutputCallHeaders);
    }

    if (generateState.defaultCreateAPIAction) {
      generateOutputCall().then((response) => {
        // console.log(`generate output Call response`, response);
        // Check if response status of /create API is failed
        if (response?.data?.status?.toLowerCase() === "failed") {
          // if it's failed then don't call the /retrieve API
          setGenerateState((prevState) => {
            return {
              ...prevState,
              isRunning: false, /// don't run the setInterval if we encounter an error
              errorMessage: response?.message || 'Unable to create the event agenda',

            }
          });
        }
        else {
          setLoadingState(10)
          setGenerateState((prevState) => {
            return {
              ...prevState,
              generateProgress: 10,
              isRunning: true,
              defaultCreateAPIAction: false,
              errorMessage: ""
            }
          });
        }
      }).catch((error) => {
        // console.log(`generate page create API call api -`, error)
        console.log("Print error...................................................", error)
        if (error?.response?.status === 401) {
          clearUserSession();
          setGenerateState((prevState) => {
            return {
              ...prevState,
              isRunning: false,
              invalidToken: true
            }
          });
        } else {
          setGenerateState((prevState) => {
            return {
              ...prevState,
              isRunning: false, /// don't run the setInterval if we encounter an error
              errorMessage: error?.message || 'Something went wrong. Create Generate Call failed. Please check logs'
            }
          });
        }
      })
    }
  }, [generateState.apiToken, inputFileName, outputFileName, generateState.tryAgain, generateState.defaultCreateAPIAction, clearUserSession])


  // useEffect(() => {
  //   if (generateState.isGenerateProcessDone) {
  //     const updatedFinalJsonOutput = getUpdatedEvents(generateState)
  //     navigate('/grid', {
  //       state: {
  //         gridData: updatedFinalJsonOutput,
  //         inputFileName: inputFileName,
  //         originalFile: location?.state?.originalFile
  //       }
  //     });
  //   }

  // }, [generateState.isGenerateProcessDone, generateState.finalJsonOutput, navigate, inputFileName, location?.state?.originalFile]);

  const handleTryAgain = () => {
    setLoadingState(5)
    setGenerateState((prevState) => {
      return {
        ...prevState,
        count: 0,
        tryAgain: true,
        isRunning: !generateState.defaultCreateAPIAction,
        generateProgress: 5,
        errorMessage: ''
      }
    });
  }

  const handleTransitionEnd = () => {
    if (generateState.isGenerateProcessDone){
      const updatedFinalJsonOutput = getUpdatedEvents(generateState)
        navigate('/grid', {
          state: {
            gridData: updatedFinalJsonOutput,
            inputFileName: inputFileName,
            originalFile: location?.state?.originalFile
          }
        });
    }
  }

  return (
    <>
      <br />
      <div className="container text-center txt-left">
        <h2 className='txt-left upload-header'>Function Agenda Builder</h2>
        <br />
        <p className='txt-left'>The event agenda is being generated. Please provide enough time for the agenda output to be created.</p>
        <h5 style={{ fontWeight: 'bold' }}
          className='txt-left upload-header customFileUploadFontColor'>Generating agenda ...
          {generateState.errorMessage && (
            <small style={{ color: '#E04E39', fontFamily: 'Graphik', fontStyle: 'normal', fontWeight: '400' }}>
              {generateState.errorMessage}
            </small>
          )}
        </h5>
        <br />
        {generateState.invalidToken && (
          <h6 className="txt-left" style={{ color: 'red' }}>Login token is either invalid or expired, please navigate to <Link to="/login">login</Link> page.</h6>
        )}
        <div className="progress" style={{ height: '20px', stroke: '#978C87', storkeWidth: '54px', borderRadius: '24px' }}>
          <div className="progress-bar" role="progressbar" aria-valuenow="0"
            style={{ width: `${loadingState}%`, background: 'gray', transition: 'width 2s ease-in-out' }}
            aria-valuemin="0" aria-valuemax="100" onTransitionEnd={handleTransitionEnd}>
          </div>
        </div>

        {generateState.errorMessage && (
          <div className="btn-right">
            <button type="button" style={{ borderRadius: '84px' }}
              data-testid='tryagain-field'
              onClick={handleTryAgain}
              className="btn  btn-outline-dark btn-lg btn-light">Try Again
            </button>
            <button type="button" style={{ borderRadius: '84px' }}
              onClick={() => navigate('/upload')}
              data-testid='cancel-field'
              className="btn  btn-outline-dark btn-lg btn-light">Cancel
            </button>
          </div>
        )}
      </div>

    </>
  );
}

export default GeneratePage;