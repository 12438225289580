import axios from 'axios'

console.log(process.env)
// export const API_BASE_URL = process.env.REACT_APP_GENAI_SALES_AGENDA_API_BASE_URL;
export const API_BASE_URL = process.env.REACT_APP_GENAI_SALES_AGENDA_BACKEND_BASE_URL;
console.log(`test unique key grid-siv`);

export const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-type': 'application/json'
    }
});

export const fetchData = async (url, params={}, headers= {}) => {
    try {
        return await axiosInstance.get(url, {params, headers});
    } catch (error) {
        // Handle the error as needed
        console.error('Error fetching data:', error);
        throw error;
    }

}

export const postAxiosCall = async (url, data={}, headers= {}) => {
    try {
        return await axiosInstance.post(url, data, {headers});
    } catch (error) {
        // Handle the error as needed
        console.error('Error posting data:', error);
        throw error;
    }
}

export const putAxiosCall = async (url, data={}, headers= {}) => {
    try {
        return await axiosInstance.put(url, data, {headers});
    } catch (error) {
        // Handle the error as needed
        console.error('Error posting data:', error);
        throw error;
    }
}

// Setup request interceptor
// axiosInstance.interceptors.request.use(config => {
//     // You can check URL or method here if you want to mock only specific calls
//     if (config.url === '/upload-rfp' && config.method === 'post') {
//         config.adapter = () => {
//             return new Promise((resolve) => {
//                 setTimeout(() => {
//                     resolve({
//                         data: {url: "http://presigneduRL"}, // Mocked response
//                         status: 200,
//                         statusText: 'OK',
//                         headers: {},
//                         config,
//                         request: {}
//                     });
//                 }, 1000); // Simulating network delay
//             });
//         };
//     } else if (config.url === 'http://presigneduRL' && config.method === 'put') {
//         config.adapter = () => {
//             return new Promise((resolve) => {
//                 setTimeout(() => {
//                     resolve({
//                         data: true, // Mocked response
//                         status: 200,
//                         statusText: 'OK',
//                         headers: {},
//                         config,
//                         request: {}
//                     });
//                 }, 1000); // Simulating network delay
//             });
//         };
//
//     } else if (config.url === '/create' && config.method === 'get') {
//         config.adapter = () => {
//             return new Promise((resolve) => {
//                 resolve({
//                     data: true, // Mocked response
//                     status: 200,
//                     statusText: 'OK',
//                     headers: {},
//                     config,
//                     request: {}
//                 });
//             });
//         };
//     } else if (config.url === '/retrieve' && config.method === 'get') {
//         // config.transitional.silentJSONParsing = false;
//         // config.transitional.forcedJSONParsing = false;
//         config.adapter = () => {
//             const mockStringifyData = JSON.stringify(mockData);
//             console.log(`type of `, typeof mockStringifyData)
//             return new Promise((resolve) => {
//                 resolve({
//                     data: mockStringifyData,
//                     status: 200,
//                     statusText: 'OK',
//                     headers: {'Content-Type': 'application/json'},
//                     config,
//                     request: {}
//                 });
//             });
//         };
//     } else if (config.url === '/json-to-excel' && config.method === 'post') {
//         config.adapter = () => {
//             return new Promise((resolve) => {
//                 resolve({
//                     data: {"url": mockFinalExcel },
//                     status: 200,
//                     statusText: 'OK',
//                     headers: {'Content-Type': 'application/json'},
//                     config,
//                     request: {}
//                 });
//             });
//         };
//     } else if (config.url === '/update' && config.method === 'post') {
//         config.adapter = () => {
//             return new Promise((resolve) => {
//                 resolve({
//                     data: true,
//                     status: 200,
//                     statusText: 'OK',
//                     headers: {'Content-Type': 'application/json'},
//                     config,
//                     request: {}
//                 });
//             });
//         };
//     }
//     return config;
// });
