import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../context/AuthContext";
import marriotLogo from '../marriott.logo.png';

const Login = () => {
    const [message, setMessage] = useState(false);
    const navigate = useNavigate();
    const { isSessionExpired, userRecords, clearUserSession } = useContext(AuthContext);

    // run the effect only once (ComponentDidMount)
    useEffect(() => {
        // if the user is not expired (session is still active)
        if (!isSessionExpired(userRecords?.expiresAt ?? "")) {
            navigate('/upload');
        } else {
            localStorage.clear();
            clearUserSession();
            navigate('/login')
        }
    }, [isSessionExpired, navigate, userRecords, clearUserSession]);

    useEffect(() => {
        const checkConfirmation = async () => {
            if (message) {
                const backendBaseUrl = process.env.REACT_APP_GENAI_SALES_AGENDA_BACKEND_BASE_URL;
                // const backendBaseUrl = 'https://sales-agenda-builder-api.emerging-tech.mdev1.cld.marriott.com/agenda-builder'
                // const backendBaseUrl = 'http://localhost:5000/agenda-builder'
                window.location.href = `${backendBaseUrl}/login`;
            }
        };

        const timer = setTimeout(() => checkConfirmation().catch(error => console.log(error)), 1000);
        return () => clearTimeout(timer);

    }, [message, navigate]);

    const pingFedSignOn = async (event) => {
        event.preventDefault();
        setMessage(true);
    }

    return (
        <>
            <div style={{
                width: '25%',
                float: 'left',
                backgroundImage: 'url(/bg.png)',
                height: '740px',
            }}></div>
            <div style={{ width: '74%', float: 'right' }}>

                <div className="container text-center">

                    <section className="vh-100">
                        <div className="container h-100">
                            <div
                                className="row d-flex justify-content-center align-items-center h-100"
                                style={{ marginBottom: '0px' }}>
                                <div className="row">
                                    <div className="offset-2 col-sm-6">
                                        <div className="card">
                                            <h5 className="card-header bg-dark">
                                                <img
                                                    src={marriotLogo}
                                                    className="d-inline-block align-top brand-image justify-content-center"
                                                    alt="React Bootstrap logo"
                                                />
                                            </h5>
                                            <div className="card-body">
                                                <h5 className="card-title">Function Agenda Builder</h5>
                                                <p className="card-text"> Click the below login button to access the Function Agenda Builder.</p>
                                                <button onClick={pingFedSignOn} className="btn btn-primary">LOGIN</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="card-body p-5 text-center">
                                    {message &&
                                        (
                                            <div
                                                className="alert alert-success"
                                                role="alert">
                                                {"Please wait signing you In..."}
                                            </div>
                                        )}
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div style={{ clear: 'both' }}></div>
            </div>
            <div style={{ clear: 'both' }}></div>
        </>
    );
};

export default Login;